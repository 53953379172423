import React ,{useEffect} from "react";
import styled from "styled-components"; 
import {Helmet} from "react-helmet";

import { pageViewsTracking } from "../ReactGAFunction"; 

const PyscriptPage = () => { 
  
  useEffect(() => {
    pageViewsTracking("PyscriptPage")
}, [])
  return (  
    <div className="application">
    <Helmet>
       <link rel="stylesheet" href="https://pyscript.net/alpha/pyscript.css" />
        <script async="true" defer src="https://pyscript.net/alpha/pyscript.js"></script>
    </Helmet>
        <div id="folium"></div>
      <py-script output="folium">
            from datetime import datetime
            now = datetime.now()
            now.strftime("%m/%d/%Y, %H:%M:%S")
        </py-script>
</div>

    // <html lang="en">
    // <head>
    //     <meta charSet="utf-8" />
    //     <meta name="viewport" content="width=device-width,initial-scale=1" />

    //     <title>PyScript Hello World</title>

    //     <link rel="icon" type="image/png" href="favicon.png" />
    //     <link rel="stylesheet" href="https://pyscript.net/alpha/pyscript.css" />
    //     <script defer src="https://pyscript.net/alpha/pyscript.js"></script>
    // </head>

    // <body>
    //         Hello world! <br/>
    //         This is the current date and time, as computed by Python:
    //         <py-script>
    //             from datetime import datetime
    //             now = datetime.now()
    //             now.strftime("%m/%d/%Y, %H:%M:%S")
    //         </py-script>
    //     </body>
    // </html>
  );
};

export default PyscriptPage;

const Section_ = styled.div`
  display: flex;
  flex-direction: column;
 
  min-height: 83vh;
  height: 100%;
  position: relative;
  align-items: center;
`;

const SubDiv = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 90%;
  }
  /* SMARTPHONES LANDSCAPE */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  /* TABLETS PORTRAIT */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }
  /* TABLET LANDSCAPE / DESKTOP */
  @media only screen and (min-width: 992px) {
  }
`;
