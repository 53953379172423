import React, { useEffect } from "react";
import styled from "styled-components";
import { pageViewsTracking } from "../ReactGAFunction";
const User_Data_Privacy = () => {
    useEffect(() => {
        pageViewsTracking("User_Data_Privacy_Page")
    }, [])
    return (
        <Section_>
            <SubDiv>
                <Subtitle>
                    <SubWrapper>
                        <h3>
                            User Data Privacy Notice
                        </h3>
                        <p>
                            This version of our User Data Privacy Notice became effective on 15 July 2020.<br /><br />
                            From time to time we will need to update this notice. Where changes are significant we’ll let all our subscribers know by sending an email to all Account Owners.</p><br /><br />
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Who are we?
                        </h3>
                        <p>
                            We are UneeQ: We provide a technology platform which is the global standard for Persona Labss- enabling the best creative minds to design and build amazing experiences that improve human life.<br /><br />
                            We, our or us: When we say we, us, or our we mean UneeQ Limited and its wholly owned subsidiaries. Our headquarters are in New Zealand but we have teams across the US, Australia and Europe too, made up of incredible and experienced AI practitioners, scientists, inventors, developers, quality experts, project managers, marketers and dreamers.<br /><br />
                            More information: If you want to learn more about us then you can visit our website and see how you can contact us, or where our nearest office to you is.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Our privacy principles
                        </h3>
                        <p>
                            <SpanDiv>
                                <span>1. Do the right thing: We want to do the right thing when it comes to privacy. This means we not only adhere to privacy law but we do what people would expect of us.<br /></span>
                                <span>2. Transparency: We want to be clear with our subscribers and with End Users (people who interact with Persona Labss) how our Platform processes Personal Data.<br /></span>
                                <span>3. Secure: We want our system to be the best it can be and part of that is securely processing the information that we do, including any Personal Data.<br /></span>
                            </SpanDiv>
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Types of Personal Data Processed
                        </h3>
                        <p>
                            There are 2 broad categories of personal data that we process:<br /><br />
                            <SpanDiv>
                                <span>1. User Data: which is information collected and generated about End Users’ interactions with a Persona Labs using our Platform.</span><br />
                                <span>2. Subscriber Data: which is information we collect about current and prospective subscribers through our website, by our sales team, or provided to us through the process of signing up to use our Platform.</span><br /><br />
                            </SpanDiv>
                            The Privacy Notice is focused on our processing of User Data. If you wish to understand more about our processing of Subscriber Data we have a specific Privacy Notice for this also.
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            User Data
                        </h3>
                        <p>
                            We offer our Platform to subscribers and they then allow End Users to interact with Persona Labss. There are a number of ways a subscriber can configure our Platform that changes the type of User Data that we gain access to and use. We require each of our subscribers to consider their particular use of our Services and share with End Users correct information about the User Data that is being collected and gain the consents that are required.<br /><br />
                            The User Data we collect and use during any given interaction with a Persona Labs may be less than the description of the User Data we collect in this Privacy Notice, depending on how a subscriber has configured our Platform.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            User Data we may collect
                        </h3>
                        <p>
                            <SpanDiv>
                            <span> 1. Audio from an End User: We may collect audio of what an End User is saying to a Persona Labs.<br /></span>
                            <span> 2. Transcripts of the conversation: We may collect text transcripts of what an End User has said to a Persona Labs, and what the Persona Labs said in reply.<br /></span>
                            <span> 3. Video data of an End User: We may collect video of an End User interacting with a Persona Labs.<br /></span>
                            <span> 4. IP addresses of End Users: We collect IP addresses of End Users who have interacted with a Persona Labs.<br /></span>
                            </SpanDiv>
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            How we use User Data
                        </h3>
                        <p>
                            Our interest in processing User Data is that we are required by contract to allow End Users to interact with Persona Labss and User Data is necessary for that purpose, and / or because we have an End User’s consent to use it. Specifically we use it to:<br /><br />
                            <SpanDiv>
                            <span> 1. Enable a Persona Labs to understand an End User: We use audio data so that a Persona Labs can understand what an End User is saying to it. In order to do this we share audio data with a third party service partner who helps us with transcription. Neither they or we store any audio User Data once we have completed the transcription.</span><br />
                            <span> 2. Enable the Persona Labs to behave appropriately: We use video data to allow a Persona Labs to behave more intelligently. For example, to know when someone is speaking to it more accurately. We do not keep any video User Data at the end of an interaction.</span><br />
                            <span> 3. Troubleshoot problems with the Platform: We may inspect conversation transcripts or audit logs containing IP addresses if we are investigating a problem with our Platform that we have been made aware of.</span><br /><br />
                            
                            </SpanDiv>
                            Aside from third party service partners who help us utilise the User Data in this way, we do not share or sell User Data to anyone unless we have consent, a legal requirement to do so, or are acting on lawful instructions from a subscriber.
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                        Your rights
                        </h3>
                        <p>
                        You are always welcome to contact us at privacy@uneeq.com if you have questions about our Privacy Notice or your Personal Data. You can ask us:<br /><br />
                        <SpanDiv>
                            <span>1. What data we may hold about you</span><br />
                            <span>2. To send a copy of any data we hold about you</span><br />
                            <span>3. To remove data we may hold about you</span><br />
                            <span>4. To withdraw your consent to us processing your data in the future</span><br />
                        </SpanDiv>
                        </p>
                    </SubWrapper>
                    


                </Subtitle>
            </SubDiv>
        </Section_>
    );
};

export default User_Data_Privacy;

const Section_ = styled.div`
display: flex;
flex-direction: column;
min-height: 83vh;
height: 100%;
position: relative;
align-items: center;
`;

const SubDiv = styled.div`
min-width: 600px;
width: 60%;
position: relative;
margin-top: 30px;

@media only screen and (min-width: 320px) and (max-width: 479px) {
  width: 90%;
}
/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
/* TABLET LANDSCAPE / DESKTOP */
@media only screen and (min-width: 992px) {
}
`;
const Subtitle = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    /* padding-top: 56.25%;*/
    padding: 50px; 
    padding-bottom: 10px;
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center; 
    flex-direction: column;
  h2{
      font-size: 2.25rem;
      /* margin-top: 30px; */
      margin-bottom: 30px;
      font-weight: 600;
    }
  `

const TopWrapper = styled.div` 
    display: flex;
    margin-top: 30px;
    padding: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h3{
        font-size: 1.75rem;
        text-align: start;
        margin-bottom: 30px;
        font-weight: normal;
    }
    p{
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: justify; 
    }
`

const SubWrapper = styled.div` 
    display: flex; 
    padding: 0px 0px 30px 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h3{
        font-size: 1.65rem;
        text-align: start;
        margin-bottom: 10px;
        font-weight: normal;
    }
    p{
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: justify; 
    }
  `

const SpanDiv = styled.div`
    width: 100%;
    padding-left: 30px;
        span{ 
            font-size: 0.75rem;
        }
`