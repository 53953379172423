import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import styled from "styled-components";
import { eventTracking } from "./ReactGAFunction";
const Dropdown = ({setOpen}) => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <DropdownStyle>
      <ul
        onClick={handleClick}
        className={click ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {MenuItems.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => {
                  setOpen(false)
                  setClick(false)
                  eventTracking('click_dropdown_mobile_'+item.title,item.title)
                }}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </DropdownStyle>
  );
};

export default Dropdown;

const DropdownStyle = styled.div`
  .dropdown-menu {
    width:fit-content !important;
    display: block;
    background:#ececec;
    /* width: 200px !important; */
    /* left: 65%; */
    position: absolute;
    top: 80px;
    /* right: -125px; */
    list-style: none;
    text-align: start;
    float: left;
    margin-left: -60px;
    /* position: relative; */
    z-index: 300;
    border-radius: 20px;
  }

  .dropdown-menu li {
    margin: auto;
    padding: 0px 5px 0px 5px;
    border-radius: 20px;
    background:#ececec;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;
    text-align: center;
  }

  .dropdown-menu li:hover {
    background:#ececec;
    cursor: pointer;
  }

  .dropdown-menu.clicked {
    display: none;
  }

  .dropdown-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: gray;
    padding: 16px;
  }

  @media screen and (max-width: 960px) {
    .dropdown-menu {
      width: 100%;
      position: absolute;
      // top: 170px;
      list-style: none;
      text-align: center;
    }
  }
`;
