import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../img/persona lab-logo2-text3.png";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Hamburger from "hamburger-react";
import Dropdown from "./Dropdown"
// styled
import styled from "styled-components";
import { eventTracking } from "./ReactGAFunction";
const Nav = () => {
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);
  return (
    <StyledNav
      style={{ display: pathname === "/homepage2" ? "none" : "flex" }}
    >
      <NavContainer>
        <Link id="Logo" to="/"
            onClick={()=>{
              console.log("clicked nav bar")
              eventTracking('click_nav_bar_home','home')
            }}>
          <img src={logo} alt="Persona Logo" />
        </Link>

        <ul>
          <li className="nav-item">
            <Link to="/get-demo"
            onClick={()=>{
              console.log("clicked nav bar")
              eventTracking('click_nav_bar_get_demo','get-demo')
            }}
            >Get a Demo</Link>
            <Line
              style={{
                left: "40%"
              }}
              transition={{ duration: 0.2 }}
              initial={{ width: "0%" }}
              animate={{ width: pathname === "/get-demo" ? "50%" : "0%" }}
            />
          </li>

          <li className="nav-item"
            style={{ display: pathname === "/hello_ian" ? "none" : "flex" }}
          >
            <Link to="/videos"
            onClick={()=>{
              console.log("clicked nav bar")
              eventTracking('click_nav_bar_videos','videos')
            }}>Videos</Link>
            <Line
              transition={{ duration: 0.2 }}
              initial={{ width: "0%" }}
              animate={{ width: pathname === "/videos" ? "50%" : "0%" }}
            />
          </li>
          {/* <li className="nav-item" style={{ display: pathname === "/hello_ian" ? "none" : "flex" }}>
            <Link to="/FAQ">FAQ</Link>
            <Line
              transition={{ duration: 0.2 }}
              initial={{ width: "0%" }}
              animate={{ width: pathname === "/FAQ" ? "50%" : "0%" }}
            />
          </li> */}
          <li className="nav-item" style={{ display: pathname === "/hello_ian" ? "none" : "flex" }}>
            <Link to="/contact-us"
            onClick={()=>{
              console.log("clicked nav bar")
              eventTracking('click_nav_bar_contact_us','contact-us')
            }}>Contact Us</Link>
            <Line
              style={{
                left: "40%"
              }}
              transition={{ duration: 0.2 }}
              initial={{ width: "0%" }}
              animate={{ width: pathname === "/contact-us" ? "50%" : "0%" }}
            />
          </li>

          <li className="mobile-div">
            <Hamburger color="white" toggled={isOpen} toggle={setOpen} />
            {isOpen && <Dropdown setOpen ={setOpen} />}
          </li>

        </ul>
      </NavContainer>
    </StyledNav>
  );
};

const NavContainer = styled.div`
    max-width: 1200px;
    display: flex;
    width: 100%;
`

const StyledNav = styled.nav`
  min-height: 8vh;
  display: flex;
  /* margin: auto; */
  padding: 1rem 2rem 1rem 2rem;

  justify-content: center;
  align-items: center;

  background: #0185ff ;
  /* width: 100%; */

  ul { 
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content:  flex-end;
    align-items: center;
    flex-wrap: nowrap;
    flex: 1 1 70rem;
    /* padding: 0rem 5rem 0rem 0rem; */
  }
  li {
    padding-left: 3rem;
    position: relative; 
    white-space: nowrap;
  }

  .nav-item {
    padding-left: 3rem;
    position: relative; 

    @media only screen and (min-width: 320px) and (max-width: 479px) {
      display: none !important;
    }
    /* SMARTPHONES LANDSCAPE */
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      display: none !important;
    }
    /* TABLETS PORTRAIT */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      display: none !important;
    }
  }

  li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    padding: 0rem 1rem;
  }
  #Logo {
    /* font-size: 2rem;
        font-family:  'DM Sans';
        font-weight: bold;
        color: red; */
    flex: 3 1 40rem;
    img {
    width: 220px;
    height: 50px;
    padding: 1rem 0rem 1rem 0rem;
    object-fit: cover;
      /* height: 1rem; */
    }
  }

  @media (max-width: 768px) {
    /* for when size is below 768px wide */
    /* background: lightgreen; */
    ul {
      flex: 1 1 20rem;
    }

    li a {
      color: #ffffff;
      text-decoration: none;
      font-size: 1rem;
      padding: 0rem 1rem;
    }

    #Logo {
      /* font-size: 2rem;
        font-family:  'DM Sans';
        font-weight: bold;
        color: red; */
      flex: 3 1 20rem;
      img {
        padding: 1rem 0rem 0rem 0rem;
        margin: 0;
        width: 70%;
        height: auto;
      }
    }
  }

  @media (max-width: 500px) {
    min-height: 5vh;
    /* for when size is below 500px wide */
    /* background: lightgoldenrodyellow; */

    #Logo {
      /* font-size: 2rem;
        font-family:  'DM Sans';;
        font-weight: bold;
        color: red; */
      flex: 3 1 20rem;
      img {
        padding: 1rem 0rem 0rem 0rem;
        margin: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  .mobile-div {
    padding: 0px;
    position: relative; 
    display: none;
    @media only screen and (min-width: 320px) and (max-width: 479px) {
      display: block;
    }
    /* SMARTPHONES LANDSCAPE */
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      display: block;
    }
    /* TABLETS PORTRAIT */
    @media only screen and (min-width: 768px) and (max-width: 959px) {
      display: block;
    }
    /* TABLET LANDSCAPE / DESKTOP */
    @media only screen and (min-width: 960px) {
    }
  }
`;
const Line = styled(motion.div)`
  height: 0.3rem; 
  background: white;
  width: 100%;
  position: absolute;
  bottom: -40%;
  left: 45%;
`;
export default Nav;
