import React, { useEffect } from "react";
import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";
import logoImg from "../../img/PL logo full logo.svg";//persona lab-logo2.png";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { pageViewsTracking, eventTracking } from "../ReactGAFunction";
import { Helmet } from "react-helmet";
const Home = () => {
  const FinishVideo = () => {
    eventTracking('video_home', 'video finished', "698233188", "video home")
  };
  const PlayVideo = () => {
    eventTracking('video_home', 'video played', "698233188", "video home")
  };
  const PauseVideo = () => {
    eventTracking('video_home', 'video paused', "698233188", "video home")
  };
  useEffect(() => {
    pageViewsTracking("home_page")
  }, [])

  return (
    <Section_>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Persona Labs - We make digital people real</title>
        <meta name="description" content="Learn how Digital Personas can help your business for a variety of use cases" />

        <meta name="twitter:card" content="summary_large_image" />

        <meta property="twitter:url" content="https://personalabs.ai/" />
        <meta property="twitter:title" content="Persona Labs" />
        <meta property="twitter:description" content="Learn how Digital Personas can help your business for a variety of use cases" />
        <meta property="twitter:image" content="https://uc13b46f399a3c7bd08965f14aa6.previews.dropboxusercontent.com/p/thumb/ABsBB1XEE8wAwjmyp-eNjbs7gvieM6r0512kR9IZ2woRQI2BiWZzy1LzYOCIZs2a1ru7OjO6ipl3s3I9OKvZXycWwGoGlyRShog-ZFBWF57qnoDu9SRBerUzq9Tcwq09Q9aYqtLam8zpub4BnvmwwlcvZ5JpxtF-iIAzSjHU8M8wLh_o_-yinHyWVqYT9kVCJO14bPm2v2t5OKSPXL_4zqGHkLVEYm0Vhb1BSUrskYTXkXkJSJPtY-HmTy31wwDTnLdAezz2ztmgK-MZLaPQyotGEwf-5fjqr0FF6atf2K3JZwA84kE6tbbeD1xMiE5b39120WgwaxTdY39WJLJbw6YDh_O33jNHhzJw4QnFNWIW2bP1xfLyTMFg0ZDSnhhylJEm5rNzTojysJdsGppZkX7SOU8Mecraq9JdT-rc0hA83g/p.png" />

        <meta property="og:url" content="https://personalabs.ai/" />
        <meta property="og:title" content="Persona Labs" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Learn how Digital Personas can help your business for a variety of use cases" />
        <meta property="og:image" content="https://uc13b46f399a3c7bd08965f14aa6.previews.dropboxusercontent.com/p/thumb/ABsBB1XEE8wAwjmyp-eNjbs7gvieM6r0512kR9IZ2woRQI2BiWZzy1LzYOCIZs2a1ru7OjO6ipl3s3I9OKvZXycWwGoGlyRShog-ZFBWF57qnoDu9SRBerUzq9Tcwq09Q9aYqtLam8zpub4BnvmwwlcvZ5JpxtF-iIAzSjHU8M8wLh_o_-yinHyWVqYT9kVCJO14bPm2v2t5OKSPXL_4zqGHkLVEYm0Vhb1BSUrskYTXkXkJSJPtY-HmTy31wwDTnLdAezz2ztmgK-MZLaPQyotGEwf-5fjqr0FF6atf2K3JZwA84kE6tbbeD1xMiE5b39120WgwaxTdY39WJLJbw6YDh_O33jNHhzJw4QnFNWIW2bP1xfLyTMFg0ZDSnhhylJEm5rNzTojysJdsGppZkX7SOU8Mecraq9JdT-rc0hA83g/p.png" />

      </Helmet>
      <SubDiv>
        <LogoDiv>
          <img src={logoImg} />
        </LogoDiv>

        <VideoMainDiv>
          {/*   for video will set audio play true and for controls to show or hidden controls */}

          {/* {Loading && <Circles arialLabel="loading-indicator"
            color="gray"
            height={300} width={100}
          />} */}
          <Vimeo
            autoplay
            paused={false}
            video={"698233188"}
            showTitle={false}
            onPlay={PlayVideo}
            onEnd={FinishVideo}
            onPause={PauseVideo}
            controls={true}
          // responsive={true}
          />
        </VideoMainDiv>
        <FormLogin>
          <BtnDiv>
            <SendBtn>
              <Link to="/get-demo"
                onClick={() => {
                  eventTracking('click_book_home', 'Book a live Demo')
                }}
              >Book a live Demo</Link>
            </SendBtn>
          </BtnDiv>
        </FormLogin>
      </SubDiv>
    </Section_>
  );
};

export default Home;

const Section_ = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 83vh;
  height: 100%;
  position: relative;
  align-items: center;
`;

const LogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: cover;
    width: 450px;
    height: 300px;
  }
`;

const VideoMainDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  background: white;
  padding: 50px;
  padding-bottom: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 400px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    height: 250px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    height: 400px;
  }
  /* width: 1200px;
  height: 800px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 300px;
    height: 250px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 650px;
    height: 400px;
  } */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* width: 1200px;
    height: 800px;

    @media only screen and (min-width: 320px) and (max-width: 479px) {
      width: 300px;
      height: 250px;
    } 
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    } 
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 650px;
      height: 400px;
    } */
  }
`;

const SubDiv = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;
  /* margin-bottom: 130px;  */

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 90%;
  }
  /* SMARTPHONES LANDSCAPE */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  /* TABLETS PORTRAIT */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }
  /* TABLET LANDSCAPE / DESKTOP */
  @media only screen and (min-width: 992px) {
  }
`;

const FormLogin = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SendBtn = styled.div`
  text-align: center;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: none;
  font-weight: bold;
  outline: 0;
  cursor: pointer;
  margin-top: 2rem;
  transition: all 0.3s ease-out;
  border-radius: 6px;
  padding: 11px 13px;
  background: #0185ff;
  font-weight: normal;
  font-size: 18px;
  :hover {
    background: #0185ff;
  }
  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
  }
`;
