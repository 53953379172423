import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import ReactModal from "react-modal";
import Vimeo from "@u-wave/react-vimeo";
import { eventTracking } from "../ReactGAFunction";
export const VideoModal = ({
  showModal,
  setShowModal,
  OnMainSignIn,
  videoID,
  video_title
}) => {

  const ModalComponent = ReactModal;



  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        eventTracking('close_video_modal_' + videoID, 'close video', videoID, video_title)
        console.log('close_video_modal_' + videoID)
        document.addEventListener("keydown", keyPress);
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    // eventTracking('close_video_modal_' + videoID, 'close video', videoID, video_title)
    // console.log('close_video_modal_' + videoID)
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);
  //onClick={closeModal}


  const FinishVideo = () => {
    eventTracking('video_modal_finished_' + videoID, 'video finished', videoID, video_title)

  };
  const PlayVideo = () => {
    eventTracking('video_modal_played_' + videoID, 'video played', videoID, video_title)
    eventTracking('video_played_' + video_title.replaceAll(" ","_"), 'video played', videoID, videoID)
  };
  const PauseVideo = () => {
    eventTracking('video_modal_paused_' + videoID, 'video paused', videoID, video_title)
  };

  console.log(videoID)

  return (
    <Background>
      <ModalComponent

        style={customStyles}
        ariaHideApp={false}
        isOpen={showModal}
        onRequestClose={() => OnMainSignIn(false)}
      >

        <VideoDiv>
          <VideoMainDiv>
            {/*   for video will set audio play true and for controls to show or hidden controls */}

            {/* {Loading && <Circles arialLabel="loading-indicator"
            color="gray"
            height={300} width={100}
          />} */}
            <Vimeo
              autoplay
              paused={false}
              video={videoID}
              showTitle={false}
              onPlay={PlayVideo}
              onEnd={FinishVideo}
              onPause={PauseVideo}
              controls={true}
            // responsive={true}
            >
            </Vimeo>

          </VideoMainDiv>
          <CloseModalButton onClick={() => {
            console.log('close_video_modal_' + videoID)
            eventTracking('close_video_modal_' + videoID, 'close video', videoID, video_title)
            OnMainSignIn(false)
          }
          }>Close</CloseModalButton>

        </VideoDiv>
      </ModalComponent>


    </Background>
  );
};

const Background = styled.div`
  width: 100%; 
  height: 100%;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const VideoDiv = styled.div`
    position: absolute;
    inset: auto;
    /* border: 1px solid rgb(204, 204, 204); */
    background: transparent;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    height: 90%; 
    width: 40%;
    max-width: 980px;
    margin-top: 50px;
    h1{
      margin-bottom:30px;
      margin-top:30px;
    }
    @media only screen and (min-width: 320px) and (max-width: 479px) {
      width: 100%;
    }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 100%;
    }
    @media only screen and (min-width: 992px) {
      width: 100%;
    }
    
`

const VideoMainDiv = styled.div`
  position: relative;
  overflow: hidden; 
  padding-top: 56.25%;
  background: transparent;
  padding: 50px;
  padding-bottom: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  width: 40%;
  height: 400px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    height: 250px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  
 
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    height: 400px;
  }
  @media only screen and (min-width: 992px) {
      width: 100%;
  }
  /* width: 1200px;
  height: 800px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 300px;
    height: 250px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 650px;
    height: 400px;
  } */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* width: 1200px;
    height: 800px;

    @media only screen and (min-width: 320px) and (max-width: 479px) {
      width: 300px;
      height: 250px;
    } 
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    } 
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 650px;
      height: 400px;
    } */
  }
`;

const VideoParagraph = styled.div`
  margin: 0;
  margin-top: 30px; 
  display: flex;
  justify-content: center;

  p {
    margin: 0px;
    margin-top: 10px;
    width: 650px;
    text-align: left;
    display: -webkit-b
  }

`

const customStyles = {
  content: {
    display: "flex",
    justifyContent: "center",
    background: "transparent",
    border: "none"
  },
};

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 60px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;
