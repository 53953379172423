import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { eventTracking } from "./ReactGAFunction";
export default function Footer(props) {
    return ( 
        <FooterDiv_> 
            <span>Copyright Red Dragon AI © 2022. 
                {/* <Link to="/terms-of-service"
                    onClick={()=>{
                    console.log("clicked nav bar")
                    eventTracking('click_nav_bar_home','home')
                    }}> Terms, Privacy & Legal
                </Link> */}
                </span> 
        </FooterDiv_>
    );
}

const FooterDiv_ = styled.div`
    flex-shrink: 0;
    text-align: center;
    background-color: white;
    color: black;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    span{
        color: black;
        a{
            text-decoration: none;
            color: black;
        }
    }
`

// const FooterCopyRight = styled.div`
//   display: flex;
//   width: 100%;
//   align-items: center;
//   justify-content: center;
//   /* margin-top: 30px;
//   margin-bottom: 30px; */
  
//   background-color: #0185ff;
//   span {
//     color:white;
//   }
// `;

// const FooterDiv = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   h1 {
//     margin: 30px;
//     font-size: 30px;

//     @media only screen and (min-width: 320px) and (max-width: 479px) {
//       /* margin-top: 470px; */
//     }
//     /* SMARTPHONES LANDSCAPE */
//     @media only screen and (min-width: 480px) and (max-width: 767px) {
//     }
//     /* TABLETS PORTRAIT */
//     @media only screen and (min-width: 768px) and (max-width: 991px) {
//       /* margin-top: 190px; */
//     }
//     /* TABLET LANDSCAPE / DESKTOP */
//     @media only screen and (min-width: 992px) {
//       margin-top: 0px;
//     }
//   }
// `;

// const RightFootDiv = styled.div`
//   margin-right: 40px;
//   width: 50%;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   @media (max-width: 768px) {
//     flex-direction: column;
//     justify-content: center;
//     margin-right: 0px;
//   }
// `;
// const LeftFootDiv = styled.div`
//   margin-left: 40px;
//   display: flex;
//   flex-direction: row;
//   width: 50%;
//   @media (max-width: 768px) {
//     flex-direction: column;
//     margin-left: 0px;
//   }
// `;

// const FooterBotDiv = styled.div`
//   display: flex;
//   width: 100%;
//   align-content: flex-start;
//   align-items: flex-start;
//   @media (max-width: 768px) {
//     align-content: center;
//     align-items: center;
//     flex-direction: column;
//   }
// `;
