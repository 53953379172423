import React from "react"; 
import styled from "styled-components";
const VideoItem = ({ src, OnGotoScreen ,    videoId,titleVid}) => { 
  return (
    <Athumbnail> 
        <img
          onClick={() => {
            OnGotoScreen(videoId,titleVid);
          }}
          src={src}
        /> 
        <InfoDiv>
        <h2>{titleVid}</h2>
        </InfoDiv>
        
    </Athumbnail>
  );
};

export default VideoItem;

const Athumbnail = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
  img {
    width: 380px;
    height: 230px;
    cursor: pointer;
    object-fit: contain;

    @media only screen and (min-width: 320px) and (max-width: 479px) {
      width: 280px;
      height: 160px;
    }
    /* SMARTPHONES LANDSCAPE */
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    }
    /* TABLETS PORTRAIT */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      flex-direction: column;
    }
  }
  label {
    align-self: flex-start;
    width: 380px;
    margin-top: 10px;
    color: white;
    font-weight: normal;

    @media only screen and (min-width: 320px) and (max-width: 479px) {
      width: 280px;
    }
    /* SMARTPHONES LANDSCAPE */
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    }
    /* TABLETS PORTRAIT */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }
  }
`;

const InfoDiv = styled.div`
  width:100%;
  text-align:center;
  padding:10px;
  h2 {
    font-size:1.25rem;
  }
`
