import React ,{useEffect} from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { pageViewsTracking } from "../ReactGAFunction";

const LiveVideo = () => {
  
useEffect(() => {
  pageViewsTracking("live_video_page")
}, [])
  return (
    <Section_> 
      <Helmet>
        <meta charSet="utf-8" />
        <title>Persona Labs - We make digital people real”</title>
      </Helmet>
      <SubDiv>
        <VideoMainDiv>
          {/*   for video will set audio play true and for controls to show or hidden controls */}
          <iframe
            src="https://reddragon.ai/demo/chat?name=Sam&room=arbitrary"
            height="100%"
            width="100%"
            title="Iframe Example" />
          {/* {Loading && <Circles arialLabel="loading-indicator"
            color="gray"
            height={300} width={100}
          />} */}
          {/* <Vimeo
            autoplay
            paused={false}
            video={"674266822"} 
            showTitle={false}
            onPlay={PlayVideo}
            onEnd={FinishVideo}
            onPause={PauseVideo}
            controls={true}
          // responsive={true}
          /> */}
        </VideoMainDiv>

      </SubDiv>
    </Section_>
  );
};

export default LiveVideo;

const Section_ = styled.div`
  display: flex;
  flex-direction: column; 
  min-height: 83vh;
  height: 100%;
  position: relative;
  align-items: center;
  text-align:center; 
`;

const VideoMainDiv = styled.div`
  position: relative;
  /* overflow: hidden;  */
  /* padding-top: 56.25%; */
  background: white;
  /* padding: 50px; */ 
  margin: 0px;
  display: flex;
  justify-content: center;
  width: 100%; 
  height: 92vh;
   
  /* width: 1200px;
  height: 800px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 300px;
    height: 250px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 650px;
    height: 400px;
  } */

  /* iframe { */
    /* position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; */
    /* width: 100%;
    height: 1200px; */
    /* width: 1200px;
    height: 800px;

    @media only screen and (min-width: 320px) and (max-width: 479px) {
      width: 300px;
      height: 250px;
    } 
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    } 
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 650px;
      height: 400px;
    } */
  /* } */
`;

const SubDiv = styled.div`
  width: 100%;
  position: relative; 
  /* margin-bottom: 130px;  */

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 90%;
  }
  /* SMARTPHONES LANDSCAPE */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  /* TABLETS PORTRAIT */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }
  /* TABLET LANDSCAPE / DESKTOP */
  @media only screen and (min-width: 992px) {
  }
`;
