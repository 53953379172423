export const MenuItems = [
  {
    title: "Home",
    path: "/",
    cName: "dropdown-link",
  },
  {
    title: "Get a Demo",
    path: "/get-demo",
    cName: "dropdown-link",
  },
  {
    title: "Video",
    path: "/Videos",
    cName: "dropdown-link",
  },
  // {
  //   title: "FAQ",
  //   path: "/",
  //   cName: "dropdown-link",
  // },
  {
    title: "Contact Us",
    path: "/contact-us",
    cName: "dropdown-link",
  },
];
