// import ReactGA from 'react-ga'
// Here is the code
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// ReactGA.initialize(key here) 
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// firebase config went here 

 
console.log(process.env.REACT_APP_appId.replaceAll("=","-"))

const firebaseConfig = {
   apiKey: process.env.REACT_APP_apiKey.toString(),
   authDomain: process.env.REACT_APP_authDomain.toString(),
   projectId: process.env.REACT_APP_projectId.toString(),
   storageBucket: process.env.REACT_APP_storageBucket.toString(),
   messagingSenderId: process.env.REACT_APP_messagingSenderId.toString(),
   appId: process.env.REACT_APP_appId.toString().replaceAll("=",":"),
   measurementId: process.env.REACT_APP_measurementId.toString()
 };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export function pageViewsTracking(nameScene) { 
   logEvent(analytics, nameScene);  
   return ""
}



export function eventTracking(event_name,action,id,name) {
   logEvent(analytics, event_name,{name: name });  
   setUserProperties(analytics, { name: name, video_id: id });
   return ""
}
 