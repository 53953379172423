import React, { useState } from "react";
import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo"; 
const Hello_ian = () => { 
  const FinishVideo = () => { };
  const PlayVideo = () => {
    console.log("play video"); 
  };
  const PauseVideo = () => {
    console.log("pause video"); 
  };
  return (
    <Section_>
      <SubDiv> 

        <VideoMainDiv>
          {/*   for video will set audio play true and for controls to show or hidden controls */}

          {/* {Loading && <Circles arialLabel="loading-indicator"
            color="gray"
            height={300} width={100}
          />} */}
          <Vimeo
            autoplay
            paused={false}
            video={"674266822"} 
            showTitle={false}
            onPlay={PlayVideo}
            onEnd={FinishVideo}
            onPause={PauseVideo}
            controls={true}
          // responsive={true}
          />
        </VideoMainDiv> 
      </SubDiv>
    </Section_>
  );
};

export default Hello_ian;

const Section_ = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  position: relative;
  align-items: center;
`;

 
const VideoMainDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  background: black;
  padding: 50px;
  padding-bottom: 10px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  width: 650px;
  height: 400px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 300px;
    height: 250px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 650px;
    height: 400px;
  }
  /* width: 1200px;
  height: 800px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 300px;
    height: 250px;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 650px;
    height: 400px;
  } */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* width: 1200px;
    height: 800px;

    @media only screen and (min-width: 320px) and (max-width: 479px) {
      width: 300px;
      height: 250px;
    } 
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    } 
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 650px;
      height: 400px;
    } */
  }
`;

const SubDiv = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;
  margin-bottom: 130px; 

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 90%;
  }
  /* SMARTPHONES LANDSCAPE */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  /* TABLETS PORTRAIT */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }
  /* TABLET LANDSCAPE / DESKTOP */
  @media only screen and (min-width: 992px) {
  }
`;

const FormLogin = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SendBtn = styled.div`
text-align: center; 
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: none;
  font-weight: bold;
  outline: 0;
  cursor: pointer;
  margin-top: 2rem;
  transition: all 0.3s ease-out;
  border-radius: 6px;
  padding: 11px 13px;
  background: #0185ff;
  font-weight: normal;
  font-size: 18px;
  :hover {
    background: #0185ff;
  }
  a{
    width: 100%;
    height:100%;
    text-decoration: none;
    color: #fff;
  }
`;
