import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
/* html, body {
  margin: 0;
  padding: 0;
}   */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif;
}    
body {
    // background: #2a2d36;  

    .react-confirm-alert-overlay {
        
    }
}

 
`;

export default GlobalStyle;
