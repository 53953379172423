import React, { useState, useEffect } from "react";
import styled from "styled-components";
import VideoItem from "../video/VideoItem";
import { VideoModal } from "../video/VideoModal";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { pageViewsTracking,eventTracking } from "../ReactGAFunction";  
import JasminAIThumbnail from "../../img/thumbnails/personalabs-jasmineai-2.png"
import JasminMultilingualThumbnail from "../../img/thumbnails/personalabs-multilingual-2.png"
import JasminRealTimeThumbnail from "../../img/thumbnails/personalabs-real-time-2.png"
const VideoPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [videoID, setVideoIDl] = useState("");
  const [video_title, setVideotitle] = useState("");
  
  const OnGotoScreen = (videoId,titleVid) => {
    setVideoIDl(videoId);
    setVideotitle(titleVid) 
    console.log('Click_Open_Video_'+titleVid)
    eventTracking('Click_Open_Video_'+videoId,videoId,titleVid)
  };

  const OnMainSignIn = (data) => {
    setVideoIDl("");
    setShowModal(false);
  };

  useEffect(() => {
    pageViewsTracking("Video_Page")
    // Update the document title using the browser API
    if (videoID) {
      console.log(videoID);
      setShowModal(true);
    }
  }, [videoID]);
  return (
    <Section_>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Persona Labs - We make digital people real</title>
      </Helmet>
      <SubDiv>
        <Subtitle>
          <h2>
            Click on one of the videos below to see examples of our Personas in action.
          </h2> 
        </Subtitle>
        <VideoMainDiv> 
          <VideoThumbnail>
            <VideoItem
              OnGotoScreen={OnGotoScreen}
              src={JasminMultilingualThumbnail}
              videoId={"698223097"}
              titleVid={"Multi-lingual"}
            />
            <VideoItem
              OnGotoScreen={OnGotoScreen}
              src={JasminRealTimeThumbnail}
              videoId={"674295958"}
              titleVid={"Real-time communication"}
            />
            <VideoItem
              OnGotoScreen={OnGotoScreen}
              src={JasminAIThumbnail}
              videoId={"674266822"}
              titleVid={"Jasmin AI"}
            />
          </VideoThumbnail>
        </VideoMainDiv>
        <FormLogin>
          <BtnDiv>
            <SendBtn>
              <Link to="/get-demo">Get a live demo</Link>
            </SendBtn>
          </BtnDiv>
        </FormLogin>
        {showModal && <VideoModal
          showModal={showModal}
          setShowModal={setShowModal}
          OnMainSignIn={OnMainSignIn}
          videoID={videoID}
          video_title={video_title}
        />}
      </SubDiv>
    </Section_>
  );
};

export default VideoPage;

const Section_ = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 83vh;
  height: 100%;
  position: relative;
  align-items: center;
`;

const SubDiv = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 90%;
  }
  /* SMARTPHONES LANDSCAPE */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  /* TABLETS PORTRAIT */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }
  /* TABLET LANDSCAPE / DESKTOP */
  @media only screen and (min-width: 992px) {
  }
`;

const Subtitle = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  /* padding-top: 56.25%;*/
  padding: 50px; 
  padding-bottom: 10px;
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  `

const VideoMainDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  padding: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 300px;
    /* height: 250px; */
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 650px;
    /* height: 400px; */
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    /* width: 1200px;
    height: 800px;

    @media only screen and (min-width: 320px) and (max-width: 479px) {
      width: 300px;
      height: 250px;
    } 
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    } 
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 650px;
      height: 400px;
    } */
  }
`;
const VideoThumbnail = styled.div`
  width: 100%;
  /* margin-top: 30px; */
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FormLogin = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SendBtn = styled.div`
  text-align: center;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: none;
  font-weight: bold;
  outline: 0;
  cursor: pointer;
  margin-top: 2rem;
  transition: all 0.3s ease-out;
  border-radius: 6px;
  padding: 11px 13px;
  background: #0185ff;
  font-weight: normal;
  font-size: 18px;
  :hover {
    background: #0185ff;
  }
  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
  }
`;
