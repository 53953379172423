import React, { useEffect } from "react";
import styled from "styled-components";
import { pageViewsTracking } from "../ReactGAFunction";
const TermsOfServices = () => {
    useEffect(() => {
        pageViewsTracking("TermsOfServices_Page")
    }, [])
    return (
        <Section_>
            <SubDiv>
                <Subtitle>
                    <SubWrapper>
                        <h3>
                            Terms of Service
                        </h3>
                        <p>
                            These Terms of Service became effective on 6 May 2022 for all customers agreeing to these Terms of Service for the first time.<br /><br />
                            If you previously agreed to a version of our Terms of Service before 6 May 2022 then we will have sent you a notice of the changes. By continuing to use our Services after 6 May 2022 you are accepting these updated terms.<br /><br />
                            If you have a separate written agreement with us then the updates to the Terms of Service will not apply to you.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Introduction
                        </h3>
                        <p>
                            1. You and Persona Labs: When we say we, us, our, or Persona Labs we mean the Persona Labs entity you contract with based on your Subscription Agreement. You or your means you or the entity or company you are authorised to represent. End Users are anyone who you allow to interact with a Persona Labs through our Services.<br /><br />
                            2. Our Services: Are all the products and services we provide now or in the future whether or not it is provided to you on a paid, trial or free of charge basis. Specifically it includes our documentation, APIs, SDKs and developer documentation collectively referred to as our Platform; and our Persona Labs creation services.<br /><br />
                            3. Changes to these terms: We may update these Terms of Service from time to time and will provide you at least 30 days prior written notice in advance of the effective date if we do. When we notify you we’ll do it by email to the owner of your account or displaying a prominent notice in our Platform.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Using the Platform
                        </h3>
                        <p>
                            4. Account creation: To use our Services you will need to create an Account and will become an Account Owner. As part of that process you’ll be asked to provide your email address, create a password and may also be required to provide other information. You are responsible for protecting your username and password from getting stolen or misused and also responsible for providing true, accurate, complete information, and are responsible for keeping it up to date.<br /><br />
                            5. Account owner responsibilities: As an Account Owner you are also responsible for controlling who has access to your Account and what level of access those people have. You will be responsible for all the actions of the people who have access to your Account including their use of our Services. You will not be liable for any loss or damage arising from unauthorized use of your Account but you agree to take all reasonable steps to prevent unauthorized access or use and agree to notify us promptly of any unauthorized access or use.<br /><br />
                            6. Provision of Services: We grant you, and any people you have authorized in accordance with these Terms a non-exclusive, non-sublicensable right and licence to access and use our Services subject to these Terms and your selected Pricing Plan or any applicable Subscription Agreement.<br /><br />
                            7. Acceptable use: You must not, and take all reasonable steps to make sure people you have authorized do not:<br /><br />
                            <SpanDiv>

                                <span>a. Interfere with or disrupt our Platform,</span><br />
                                <span>b.  Circumvent the security of our Platform,</span><br />
                                <span>c.  Perform any penetration testing or load testing on the Platform without permission,</span><br />
                                <span>d. Reverse engineer, decompile or prepare derivative works of our Platform or otherwise access the source code,</span><br />
                                <span>e. Infringe the intellectual property rights of anyone when using our Services,</span><br />
                                <span>f. Use our Services in any way that violates any applicable laws,</span><br />
                                <span>g. Use our Services in a way that is harmful, threatening, abusive, harassing, vulgar, obscene, defamatory, invasive of someone’s privacy, hateful or otherwise objectionable,</span><br />
                                <span>h. Store or allow mass download or batch download of the Platform output other than temporarily for the purposes of serving the output to an End User,</span><br />
                                <span>i. Use the Services to create a substantially similar product or service.</span><br /><br />
                            </SpanDiv>
                            8. Inappropriate use of Persona Labss. You will also not use any Persona Labs or any images of the Persona Labs we provide to you in conjunction with any content which is (a) objectionable, cruel or insensitive, constitutes hate speech, is violent in nature, or (b) is misleading or inaccurate. We reserve the right to make a determination about what constitutes inappropriate use for the purposes of this clause.<br /><br />
                            9. Suspension: We may suspend your Account’s access to our Services if we believe you have breached any of these Acceptable use provisions. We will inform you of the breach and you will need to demonstrate to us, to our satisfaction, that the breach has been resolved.<br /><br />
                            10. Third party services: Our Platform uses some third party services to enable its functionality. These services have additional terms that apply to you. These include:<br /><br />
                            <SpanDiv>
                                <span>a. Speech transcription</span><br />
                                <span>b. Pre-created digital voices, where you utilise them provided by Google, AWS and WellSaid Labs</span><br />
                                <span>c. Custom synthesised voices, where you have created one</span>
                            </SpanDiv>
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Changes to the Platform
                        </h3>
                        <p>
                            11. New or revised Services: We will introduce new features and capabilities to our Services over time. When we do so it might require changes or additions to our Terms. If so, we will notify before you start using those services.<br /><br />
                            12. Breaking changes and removal of Services: From time to time we may be required to make breaking changes to our Platform APIs or SDKs, or decide to remove capabilities from our Services. If we make such changes we will notify you at least 6 months prior. This period may be shorted by agreement from customers who are utilising affected API or SDK versions, or affected Services.<br /><br />
                            13. Browser and OS support: Due to the changing usage patterns of browsers and operating systems, we may revise our support policy every 3 months. At such time we may change the browsers, browser versions, operating systems, or operating system versions (or combinations thereof) that are supported. We will not remove support for any browser, operating system, or operating system version without at least 30 days notice.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Fees and payment terms
                        </h3>
                        <p>
                            14. Free trial: You may take up our offer to trial our Platform for a period of time. The trial period starts on the day you create your Account for a specified period of time. If you wish to continue to use our Services at the end of this trial period you will need to pay fees associated with one of our Pricing Plans or have a Subscription Agreement with us.<br /><br />
                            15. Pricing Plans and Subscription Agreements: To use our Services you will need to select a Pricing Plan or have a Subscription Agreement with us. Pricing Plans and Subscription Agreements describe your entitlements, any usage limits, fees and invoicing, payment, auto-renewal and termination terms. You agree to pay the fees associated with your selected Pricing Plan or Subscription Agreement.<br /><br />
                            16. Automatic renewal: When your subscription term concludes your subscription will automatically renew for another term of the same length unless your Subscription Agreement specifies otherwise. If you do not want your subscription to renew you must let us know prior to the end of your subscription term.<br /><br />
                            17. Changes to Pricing Plans: We may from time to time change our Pricing Plans including changing entitlements or fees. Any changes will apply to you the next time your term renews and these changes will not apply retrospectively to your current term. When we are making changes to Pricing Plans we will let you know in the same way we would other changes to these Terms.<br /><br />
                            18. Amounts charged in advance: Any amounts charged in advance on a monthly or annual basis are non-refundable, including if you use only part of a month, part of a year or part of your entitlement. Subscription fees and additional personas are charged in advance. Additional sessions are charged in arrears.<br /><br />
                            19. Invoice payment terms: Unless stated otherwise in your Subscription Agreement all invoiced amounts are due and payable within 30 days of the invoice date.<br /><br />
                            20. Credit card automatic payment terms: If you have elected to pay by credit card automatic payment, or your Pricing Plan requires it, invoices are due for immediate payment and we will charge your credit card at the point that we issue you an invoice.<br /><br />
                            21. Late fees: Unless you are disputing invoiced amounts, if you have not not paid fees by the due date, we may charge you late interest at the rate of 1.5% per month. By doing so we have not limited our ability to also suspend your Account for non payment.<br /><br />
                            22. Suspension: Unless you are disputing invoiced amounts, if any amount is 30 days or more overdue we may suspend your Account until overdue amounts are paid in full. By doing so we have not limited our ability to charge you late fees.<br /><br />
                            23. Taxes: Unless otherwise specified, invoiced amounts do not include any taxes levies, duties or similar governmental assessments of any nature (collectively, Taxes). If any Taxes are payable, then in addition to subscription fees you will pay us a further amount equal to those Taxes subject to us sending you a valid tax invoice.<br /><br />
                            24. Other tax obligations: You are responsible for paying all other external fees and taxes associated with your use of our services wherever levied. Your responsibility includes withholding tax if it applies, unless we already process that withholding tax.<br /><br />
                            25. Future Services: You agree that your use of our Services and payment of subscription fees is not contingent on the delivery of any future Services or dependent on any representations made by us regarding future Services.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Measuring usage
                        </h3>
                        <p>
                            26. Sessions: A session is an interaction between a person (End User) and a Persona Labs.<br /><br />
                            27. Billable sessions: For the purposes of determining plan entitlements and usage fees, interactions with a duration of less than 10 seconds are not considered a session. An interaction of greater than 10 minutes will be charged as multiple sessions, each a maximum of 10 minutes. i.e, a 25 minute interaction results in 3 sessions. The length of an interaction is measured from the moment a session with a Persona Labs is started by the End User to the moment the session is ended by (a) the End User; or (b) the Platform due to inactivity.<br /><br />
                            28. Persona: A persona is one set of configurations that defines how the Platform and a Persona Labs operates during interactions. It specifies the Persona Labs an End User will interact with, the background image, the language understood by the Persona Labs and the voice used by the Persona Labs and the conversation service API the Platform should use to facilitate conversations.<br /><br />
                            29. Persona limits: The total number of non-deleted personas are used for the purposes of determining plan entitlements and usage fees.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Data Processing
                        </h3>
                        <p>
                            29. Permission: You agree that we will process Subscriber Data and End User Data as described in our User Data Privacy Notice and Subscriber Data Privacy Notice.<br /><br />
                            30. End User consent: You agree that you will obtain and maintain all required consents required by law from End Users to allow us to process End User Data.<br /><br />
                            31. Withdrawal of consent: You agree that you will allow End Users to withdraw their consent for data processing. If this happens you agree to (a) notify us, and (b) remove that End User’s access to our Services, and (c) remove all End User Data from the Platform or request we do so.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Data Processing
                        </h3>
                        <p>
                            32. Permission: You agree that we will process Subscriber Data and End User Data as described in our User Data Privacy Notice and Subscriber Data Privacy Notice.<br /><br />
                            33. End User consent: You agree that you will obtain and maintain all required consents required by law from End Users to allow us to process End User Data.<br /><br />
                            34. Withdrawal of consent: You agree that you will allow End Users to withdraw their consent for data processing. If this happens you agree to (a) notify us, and (b) remove that End User’s access to our Services, and (c) remove all End User Data from the Platform or request we do so.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            IP and Confidentiality
                        </h3>
                        <p>
                            35. Pre-existing IP: You retain any intellectual property rights prior to using our Services. We own any intellectual property rights prior to you using our Services.<br /><br />
                            36. New IP: Any new intellectual property you create, you own and any new intellectual property that we create, we own.<br /><br />
                            37. Our IP in our Services: We reserve all intellectual property rights in our Services and no rights are granted to you other than those expressly set out in these Terms or in associated Subscription Agreements.<br /><br />
                            38. Our IP in Persona Labs: We reserve all intellectual property rights in the Persona Labss we create and no rights are granted to you other than those expressly set out in these Terms and the rights outlined in the Persona Labs Creation Agreement where you have commissioned us to create a Persona Labs for your exclusive use.<br /><br />
                            39. Confidentiality: When using our Services, you may share confidential information with us, and you may become aware of confidential information about us. You and we both agree to take reasonable steps to protect this confidential information from being accessed by unauthorised individuals and not use it for any purposes outside those contemplated by these Terms and any Subscription Agreement.<br /><br />
                            40. Required disclosure: You or we may share each other’s confidential information with legal or regulatory authorities if required to do so.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Termination
                        </h3>
                        <p>
                            41. For convenience: You may only terminate for convenience during your subscription term if your Subscription Agreement allows you to do so. Typically this is not the case.<br /><br />
                            42. For breach: You or we may terminate a Subscription Agreement for breach if the other party is in material breach and fails to cure that breach within 30 days of being given notice.<br /><br />
                            43. Effect of termination: If a Subscription Agreement is terminated then all rights granted in these Terms and the Subscription Agreement cease except to the extent necessary to give effect to rights or obligations arising prior to termination. Confidentiality obligations will continue after termination.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Indemnities
                        </h3>
                        <p>
                            44. You indemnify us: You indemnify us against all losses, costs (including legal costs), expenses, demands or liability that we incur due to (a) your use of our Services, or (b) an End User’s use of our Services that you facilitate.<br /><br />
                            45. We indemnify you: We indemnify you against all losses, costs (including legal costs), expenses, demands or liability from a third party claiming that our Services infringe their intellectual property rights.<br /><br />
                            46. Exclusions: We will not indemnify you if the third party claim arises due to your breach of these Terms of Service.
                            47. Remedies: If we believe our Services might infringe a third party’s intellectual property rights then we may at our sole option and expense (a) procure a right for you to continue to use our Services, or (b) modify the Services to make them non-infringing without materially reducing their functionality. If we do not believe these options are commercially reasonable we may suspend or terminate your use of those Services.<br /><br />
                            48. Conditions: Indemnities only apply so long as:<br />
                            <SpanDiv>
                            <span>a .The indemnified party has promptly notified in writing the indemnifying party of any allegations or claims. To the extent this notice is not prompt and causes prejudice to the indemnifying party the indemnifying party’s liability will be reduced in proportion to the prejudice.</span><br /><br />
                            <span>b .The indemnifying party has sole and exclusive authority to defend and settle any allegations or claims. However, any settlement requiring the indemnified party to admit liability, pay money or take any action will require their written consent, not to be unreasonably withheld.</span><br /><br />
                            </SpanDiv>
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Liability and disclaimers
                        </h3>
                        <p>
                            49. Limitation of indirect liability: TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER PARTY, NOR OUR SUPPLIERS, WILL BE LIABLE UNDER THESE TERM OF SERVICE FOR LOST REVENUES OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF DIRECT DAMAGES DO NOT SATISFY A REMEDY.<br /><br />
                            50. Limitation of liability: TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER PARTY, NOR OUR SUPPLIERS, MAY BE HELD LIABLE UNDER THESE TERMS OF SERVICE FOR MORE THAN THE AMOUNT PAID BY CUSTOMER TO Persona Labs UNDER THESE TERMS OF SERVICE DURING THE TWELVE MONTHS PRIOR TO THE EVENT GIVING RISE TO LIABILITY.<br /><br />
                            51. Exceptions: These limitations do not apply to breach of intellectual property rights, confidentiality obligations or your payment obligations.<br /><br />
                            52. Disclaimer: EXCEPT AS EXPRESSLY PROVIDED FOR IN THESE TERMS OF SERVICE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR SUPPLIERS DO NOT MAKE ANY OTHER WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE AND NON-INFRINGEMENT.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Other terms
                        </h3>
                        <p>
                            53. Notices: All legal notices you send to us must be sent to legal@uneeq.com. Any notices we send to you will be sent to the Account Owner’s email address.<br /><br />
                            54. Publicity: You are permitted to state publicly that you are a customer of our Services. We may include you or the company you are authorized to represent in a list of our customers. Neither party needs approval if it is repeating a public statement that is substantially similar to one previously agreed. You or us may revoke permission with written notice to the other party and a reasonable period to stop the use.<br /><br />
                            55. Assignment: You may not assign any right or obligation of these Terms of Service without our written consent which we will not unreasonably withhold or delay. We may assign any of our rights or obligations to any of our entities.<br /><br />
                            56. Events outside our control: Neither you or us will be liable for failure or delay in performance to the extent caused by circumstances beyond its reasonable control.<br /><br />
                            57. No Agency: These Terms of Service do not create any agency, partnership or joint venture between you and us.<br /><br />
                            58. No Waiver: Neither party will be treated as having waived any rights by not exercising (or delaying the exercise of) any rights under these Terms of Service.<br /><br />
                            59. Severability: If any term (or part of a term) of these Terms of Service is invalid, illegal, or unenforceable, the rest of the Terms of Service will remain in effect.<br /><br />
                            60. No Third-Party Beneficiaries: These Terms of Service do not confer any benefits on any third party unless it expressly states that it does.<br /><br />
                            61. Equitable Relief: Nothing in these Terms of Service affects your or our ability to seek equitable relief.<br /><br />
                            62. Disputes: If a dispute arises, either party will give the other party a notice setting out, in summary, the nature of the dispute (Dispute Notice). Representatives of the parties will meet within 14 days of the Dispute Notice to attempt to resolve the dispute (either party may use mediation or expert opinion to assist). If the dispute is not resolved within 30 days of the Dispute Notice, either party may pursue its legal remedies.<br /><br />
                            63. Conflicting Terms: In the case of any conflict between these Terms of Service and a schedule, exhibit, Subscription Agreement, these terms and conditions shall control and govern, unless otherwise expressly stated to the contrary. To the extent that the terms and conditions do not conflict with those of any Subscription Agreement, the terms and conditions of each document shall be considered cumulative and complementary and shall not supersede one another.<br /><br />
                            64. Entire Agreement: These Terms of Service sets out all terms agreed between you and us and supersedes all other agreements between the parties relating to its subject matter. In entering into these Terms of Service, neither party has relied on, and neither party will have any right or remedy based on, any statement, representation or warranty (whether made negligently or innocently), except those expressly set out in these Terms of Service.<br /><br />
                        </p>
                    </SubWrapper>
                    <SubWrapper>
                        <h3>
                            Persona Labs entities, law and venue
                        </h3>
                        <p>
                            53. Free trial and starter plan: When you sign up for a free trial or our Starter plan, our services are provided to you by UneeQ Inc, our US subsidiary.<br /><br />
                            53. Other plan types: Unless your Subscription Agreement says otherwise, if you are a customer of our Pro or Enterprise plans our services will be provided by:<br /><br />
                        </p>
                    </SubWrapper>


                </Subtitle>
            </SubDiv>
        </Section_>
    );
};

export default TermsOfServices;

const Section_ = styled.div`
display: flex;
flex-direction: column;
min-height: 83vh;
height: 100%;
position: relative;
align-items: center;
`;

const SubDiv = styled.div`
min-width: 600px;
width: 60%;
position: relative;
margin-top: 30px;

@media only screen and (min-width: 320px) and (max-width: 479px) {
  width: 90%;
}
/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 480px) and (max-width: 767px) {
}
/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
/* TABLET LANDSCAPE / DESKTOP */
@media only screen and (min-width: 992px) {
}
`;
const Subtitle = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    /* padding-top: 56.25%;*/
    padding: 50px; 
    padding-bottom: 10px;
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center; 
    flex-direction: column;
  h2{
      font-size: 2.25rem;
      /* margin-top: 30px; */
      margin-bottom: 30px;
      font-weight: 600;
    }
  `

const TopWrapper = styled.div` 
    display: flex;
    margin-top: 30px;
    padding: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h3{
        font-size: 1.75rem;
        text-align: start;
        margin-bottom: 30px;
        font-weight: normal;
    }
    p{
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: justify; 
    }
`

const SubWrapper = styled.div` 
    display: flex; 
    padding: 0px 0px 30px 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    h3{
        font-size: 1.65rem;
        text-align: start;
        margin-bottom: 10px;
        font-weight: normal;
    }
    p{
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: justify; 
    }
  `

const SpanDiv = styled.div`
    width: 100%;
    padding-left: 30px;
        span{ 
            font-size: 0.75rem;
        }
`