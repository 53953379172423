import React, { useState,useEffect } from "react";
import styled from "styled-components";
import {
  CountryDropdown, 
} from "react-country-region-selector";
import { useForm } from "react-hook-form";
import { init, sendForm } from "emailjs-com";
import { pageViewsTracking } from "../ReactGAFunction";

init("user_8FXHyc1nmEWUTC8pvNpBm");

const ContactUs = () => {
  
useEffect(() => {
  pageViewsTracking("Contact_Page")
}, [])
  const [country, setCountry] = useState(""); 
  const [checked, setchecked] = useState(false);
  const [sendMg, setsendMg] = useState(false);

  let digitalPeople = [
    "I’m interested in having a Persona in my company for a particular project",
    "I’m interested to find out more but not ready to purchase",
    "I’m interested in a partnership with Persona Labs",
  ];

  const { register, handleSubmit,  errors } = useForm();
  const [contactNumber, setContactNumber] = useState("000000");

  const generateContactNumber = () => {
    const numStr = "000000" + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };
  const onSubmit = (data) => {
    const form = document.querySelector("#contact-form");

    sendForm("default_service", "template_k8wdqaf", "#contact-form").then(
      function (response) {
        setsendMg(true);
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
    console.log("data", data);
  };
  // const submit = () => {
  //   let data = {
  //     country: country,
  //     firstName: firstName,
  //     lastName: lastName,
  //     companyName: companyName,
  //     businessName: businessName,
  //   };
  //   console.log(data);
  // };

  return (
    <Section_>
      <SubDiv>
        <TopDivOuter>
          <TopDivInside>
            <DivTitle>
              <h1>Find out more about getting a Persona for your company.</h1>
            </DivTitle>
            <DivP>
              <p>
                Personas can handle in person conversations with both internally for your team and for your external customers. Whether its for internal HR, customer service, or just delivering a personal touch, Personas can be used to provide faster and more interactive contact with people to get results.
              </p>
              <p>
                Let us know a bit more about your use case:
              </p>
            </DivP>
          </TopDivInside>
        </TopDivOuter>
        {sendMg && (
          <MessageDiv>
            <h2>Thanks for submitting the form</h2>
          </MessageDiv>
        )}
        {!sendMg && (
          <FormLogin id="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <Fieldset>
              <Inside_Fieldset>
                <label>
                  <span>First Name</span>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Inside_Input>
                  <Input_user
                    type="text"
                    {...register("first_name", {
                      required: true,
                      maxLength: 20,
                    })}
                  />
                </Inside_Input>
              </Inside_Fieldset>
              <Inside_Fieldset>
                <label>
                  <span>Last Name</span>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Inside_Input>
                  <Input_user
                    type="text"
                    {...register("last_name", {
                      required: true,
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                </Inside_Input>
              </Inside_Fieldset>
            </Fieldset>
            <Fieldset>
              <Inside_Fieldset style={{ width: "100%" }}>
                <label>
                  <span>Business Email</span>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Inside_Input>
                  <Input_user
                    type="email"
                    {...register("business_email", { required: true })}
                  />
                </Inside_Input>
              </Inside_Fieldset>
            </Fieldset>
            <Fieldset>
              <Inside_Fieldset style={{ width: "100%" }}>
                <label>
                  <span>Company name</span>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Inside_Input>
                  <Input_user
                    type="text"
                    {...register("company_name", {
                      required: true,
                      maxLength: 20,
                    })}
                  />
                </Inside_Input>
              </Inside_Fieldset>
            </Fieldset>
            <Fieldset>
              <Inside_Fieldset style={{ width: "100%" }}>
                <label>
                  <span>Country</span>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select_div {...register("country")}>
                  <CountryDropdown
                    name="country"
                    defaultOptionLabel="Please Select"
                    value={country}
                    onChange={(val) => setCountry(val)}
                  // {...register("country_name")}
                  />
                </Select_div>
              </Inside_Fieldset>
            </Fieldset>
            <Fieldset>
              <Inside_Fieldset style={{ width: "100%" }}>
                <label>
                  <span>
                    Which of these best describes your interest in Personas?
                  </span>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Select_div>
                  <select defaultValue="default" {...register("interest_message")}>
                    <option value="default" disabled hidden>
                      Choose here
                    </option>
                    {digitalPeople.map((n) => (
                      <option key={n} value={n}>{n}</option>
                    ))}
                  </select>
                </Select_div>
                {/* <Inside_Input>
                  <Input_user
                    type="text"
                    name="interest_message"
                    {...register("interest_message", {
                      required: true,
                      maxLength: 20,
                    })}
                  />
                </Inside_Input> */}
              </Inside_Fieldset>
            </Fieldset>
            <Fieldset>
              <Inside_Fieldset style={{ width: "100%" }}>
                <InputDivCheckBox>
                  <input
                    type="checkbox"
                    id="checkedMe"
                    onChange={(e) => {
                      if (checked) {
                        setchecked(false);
                      } else {
                        setchecked(true);
                      }

                      console.log("onchange", checked);
                    }}
                    checked={checked}
                  // onClick={(event) => handleChechbox(event)}
                  />{" "}
                  <label>
                    I agree to be contacted by Persona Labs per{" "}
                    <a>privacy policy</a>
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </InputDivCheckBox>
              </Inside_Fieldset>
            </Fieldset>
            <input type="hidden" name="contact_number" value={contactNumber} />
            <BtnDiv>
              <SendBtn type="submit" value="Request a Demo" />
            </BtnDiv>
          </FormLogin>
        )}
      </SubDiv>
    </Section_>
  );
};

export default ContactUs;

const MessageDiv = styled.div`
  h2 {
    font-weight: 400;
    letter-spacing: 0px;
    font-style: normal;
    font-size: 16px;
  }
`;

const Section_ = styled.div`
  display: flex;
  flex-direction: column;
  
  min-height: 83vh;
  height: 100%;
  position: relative;
  align-items: center;
`;

const SubDiv = styled.div`
  width: 40%;
  position: relative;
  margin-bottom: 130px;
  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 90%;
  }
  /* SMARTPHONES LANDSCAPE */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  /* TABLETS PORTRAIT */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }
  /* TABLET LANDSCAPE / DESKTOP */
  @media only screen and (min-width: 992px) {
  }
`;

const TopDivOuter = styled.div`
  font-weight: 400;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3;
  color: #333333;
  box-sizing: border-box;
  clear: left;
  position: relative;
  float: left;
  width: 100%;
  margin-right: 4%;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const TopDivInside = styled.div`
  font-weight: 400;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3;
  color: #333333;
  box-sizing: border-box;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  min-height: 0px;
`;

const DivTitle = styled.div`
  font-weight: 400;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3;
  color: #333333;
  box-sizing: border-box;
  flex-wrap: nowrap;
  align-items: center;
  display: block;
  margin-top: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  h1 {
    box-sizing: border-box;
    font-weight: 700;
    letter-spacing: 0px;
    font-style: normal;
    font-size: 3rem;
    flex-grow: 0;
    padding: 0;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    color: #333333;
    --fontSize: 48;
    line-height: 1.16;
  }
`;

const DivP = styled.div`
  font-weight: 400;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3;
  color: #333333;
  box-sizing: border-box;
  p {
    color: #333333;
    margin: 0 0 20px;
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const FormLogin = styled.form`
  width: 100%;
`;

const Fieldset = styled.div`
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;
  max-width: 100%;
`;

const Inside_Fieldset = styled.div`
  box-sizing: border-box;
  margin-bottom: 18px;
  width: 50%;
  float: left;
  label {
    display: block;
    float: none;
    width: auto;
    font-weight: 500;
    line-height: 20px;
    padding-top: 0;
    margin-bottom: 4px;
    box-sizing: border-box;
    font-size: 20px;
    color: #333333;
    span {
      font-weight: 500;
      line-height: 20px;
      font-family: "DM Sans", sans-serif;
      font-size: 16px;
      color: #333333;
      box-sizing: border-box;
    }
  }
`;

const Inside_Input = styled.div`
  box-sizing: border-box;
  margin-right: 8px;
`;

const Input_user = styled.input`
  display: inline-block;
  height: 40px;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 22px;
  color: #33475b;
  border: 1px solid #cbd6e2;
  border-radius: 3px;
  background-color: #f5f8fa;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  min-height: 27px;
`;

const Select_div = styled.div`
  select {
    display: inline-block;
    height: 40px;
    font-size: 20px;
    font-weight: normal;
    line-height: 22px;
    color: #33475b;
    border: 1px solid #cbd6e2;
    border-radius: 3px;
    background-color: #f5f8fa;
    width: 99%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    min-height: 27px;
    option{
    font-size:16px;
    padding:10px;
    margin:20px;
    margin-top:30px;
    margin-bottom:30px;
    }
  }
`;

const InputDivCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
  align-items: center;
  justify-content: center;

  input[type="checkbox"] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
    display: inline-block;
    width: auto;
    margin-right: 10px;
  }

  label {
    margin: 0px;
    font-size: 16px;
    width: 100%;
    @media only screen and (min-width: 320px) and (max-width: 479px) {
      font-size: 16px;
    }
    /* SMARTPHONES LANDSCAPE */
    @media only screen and (min-width: 480px) and (max-width: 767px) {
    }
    /* TABLETS PORTRAIT */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }
    /* TABLET LANDSCAPE / DESKTOP */
    @media only screen and (min-width: 992px) {
    }
  }
  span {
    font-size: 20px;
    width: 100%;
  }
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SendBtn = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: none;
  font-weight: bold;
  outline: 0;
  cursor: pointer;
  margin-top: 2rem;
  transition: all 0.3s ease-out;
  border-radius: 6px;
  padding: 11px 13px;
  background: #0185ff;
  color: #fff;
  font-weight: normal;
  font-size: 18px;
  :hover {
    background: #0185ff;
  }
`;
