import React ,{useEffect} from "react";
import styled from "styled-components"; 

import { pageViewsTracking } from "../ReactGAFunction"; 

const FAQPage = () => { 
  
  useEffect(() => {
    pageViewsTracking("FAQ_Page")
}, [])
  return (
    <Section_>
      <SubDiv>FAQ Page</SubDiv>
    </Section_>
  );
};

export default FAQPage;

const Section_ = styled.div`
  display: flex;
  flex-direction: column;
 
  min-height: 83vh;
  height: 100%;
  position: relative;
  align-items: center;
`;

const SubDiv = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;

  @media only screen and (min-width: 320px) and (max-width: 479px) {
    width: 90%;
  }
  /* SMARTPHONES LANDSCAPE */
  @media only screen and (min-width: 480px) and (max-width: 767px) {
  }
  /* TABLETS PORTRAIT */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
  }
  /* TABLET LANDSCAPE / DESKTOP */
  @media only screen and (min-width: 992px) {
  }
`;
