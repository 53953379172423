import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import EmailForm from "./Component/pages/EmailForm";
import Nav from "./Component/Nav";
import Home from "./Component/pages/Home";
import GlobalStyle from "./Component/GlobalStyle";
import VideoPage from "./Component/pages/VideoPage";
import FAQPage from "./Component/pages/FAQPage";
import ContactUsPage from "./Component/pages/ContactUsPage";
import Hello_ian from "./Component/pages/Hello_ian";
import Home2 from "./Component/pages/Home2";
import FooterDiv from "./Component/Footer";
import LiveVideo from "./Component/pages/LiveVideo";
import ContactUs from "./Component/pages/ContactUs";
import TermsOfServices from "./Component/pages/TermsOfServices";
import User_Data_Privacy from "./Component/pages/User_Data_Privacy";
import PyscriptPage from "./Component/pages/PyscriptPage";
require('dotenv').config()
console.log(process.env) 
function App() {
  // const { pathname } = useLocation();
  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
         <Nav /> 
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/homepage2" exact>
            <Home2 />
          </Route>
          <Route path="/get-demo" exact>
            <EmailForm />
          </Route> 
          <Route path="/videos" exact>
            <VideoPage />
          </Route>
          <Route path="/FAQ" exact>
            <FAQPage />
          </Route>
          <Route path="/ContactUs" exact>
            <ContactUsPage />
          </Route> 
          <Route path="/hello_ian" exact>
            <Hello_ian />
          </Route> 
          <Route path="/livedemo" exact>
            <LiveVideo />
          </Route> 
          <Route path="/contact-us" exact>
            <EmailForm />
          </Route> 
          <Route path="/terms-of-service" exact>
            <TermsOfServices />
          </Route> 
          <Route path="/policy-privacy" exact>
            <User_Data_Privacy />
          </Route> 
          <Route path="/pyscriptPage4567" exact>
            <PyscriptPage />
          </Route> 
        </Switch>
        <FooterDiv/> 
      </BrowserRouter>
    </div>
  );
}

export default App;
